import React, {useState} from "react"
import {Container,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import HTMLReactParser from 'html-react-parser';

import {config} from '../../components/Common/constant';

import { useContainerContext } from "../../store/ContainerContext";
import {isBrowser} from "../../services/auth"

const TitleBlock = (props) => {

    const context = useContainerContext();
    const { preview_sub_page, present_type, preview_tab } = context.state;
    const { PreviewSubPageDispatch, PreviewTabDispatch } = context.actions;

    const navigateSubPage = (preview_sub_page) => {
        PreviewSubPageDispatch(preview_sub_page);
    }

    const navigateLink = (e,tab_name, prev_tab, next_tab) => {
        //console.log('link---', link)
        //navigate(link);
        if(isBrowser()){
            window.scrollTo(0, 0);
        }        
        PreviewTabDispatch({current_tab:tab_name, prev_tab:prev_tab, next_tab:next_tab});
    }

    //console.log("Type2", props.Type, props.PrevPage, props.NextPage, preview_sub_page);
    //console.log("present_type", present_type, props.Type, props.Title);
    return(
        <>
            <h2>
                <div className={`${present_type === "custom_client" && props.instruct === true  ? 'sml_wdth' : ''}`}>{props.Title && HTMLReactParser(props.Title)}</div>
                
                {
                props.Type === "preview" ? (
                    <>
                        <div className={"title_nav"}>
                            { props.PrevPage && 
                                <a href={'javascript:;'} onClick={() => navigateSubPage(props.PrevPage)} className="left-icon" title="Previous">
                                    &lt; Prev
                                </a>
                            }
                            
                            { props.NextPage &&
                                <a href={'javascript:;'} onClick={() => navigateSubPage(props.NextPage)} className="right-icon" title="Next">
                                    Next &gt;
                                </a>
                            }
                        </div> 

                        {present_type === "custom_client" && (
                            props.instruct === true && <a href="javascript:;" onClick={(e) => navigateLink(e, "Fees", '', "Fees")} className="btn btn-primary instruct_btn">Instruct</a>                                
                            )
                        }
                    </>
                ) : (
                    <>
                    {
                        (props.PreviousLink !== '' || props.NextLink !== '') && (
                            <>
                                <div className={"title_nav"}>
                                    { props.PreviousLink && 
                                        <Link to={props.PreviousLink} className="left-icon" title="Previous">
                                            &lt; Prev

                                            {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1785 0.678711L14.9463 2.44648L7.70517 9.6876L14.9463 16.9287L13.1785 18.6965L4.16964 9.6876L13.1785 0.678711Z" fill="#67707B"/>
                                            </svg> */}
                                        </Link>
                                    }
                                    
                                    { props.NextLink &&
                                    <Link to={props.NextLink} className="right-icon" title="Next">
                                        Next &gt;
                                        
                                        {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                                        </svg> */}
                                    </Link>
                                    }
                                </div>  
                                
                            </>                 
                        )                        
                    }
                    {/* {
                        props.Title === "Your Payment Options" || props.Title === "How do our costs compare with other agents?" ? ('') : <Link to={config.custom_fees} className="btn btn-primary instruct_btn">Instruct Preston Baker</Link>
                    } */}
                    </>                    
                )
            }
            
            </h2>
        </>
    )
}
export default TitleBlock