import React, {useEffect, useState}  from "react"
import dateFormat from 'dateformat';
import moment from 'moment';
import {Container,Navbar,Nav} from 'react-bootstrap';
import $ from "jquery";
import HTMLReactParser from 'html-react-parser';

import FullCalendar from "@fullcalendar/react";
import { Calendar, Tooltip } from '@fullcalendar/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import VideoModule from "./video-module"

import {config} from '../../components/Common/constant';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL, GET_PHOTOGRAPHER_NAME_PROPERTY } from "../../queries/common_use_query";
import { useContainerContext } from "../../store/ContainerContext";
import { handleLogin, isBrowser, setUser, getUser, isLoggedIn, resetPassword } from "../../services/auth"
import Title_Block from  "./title_block";

import { SignInAgreement_API } from "../../queries/api_links";

const Photographer_DateTime = (props) => {
    const calendarComponentRef = React.createRef();
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    const context = useContainerContext();
    const { property_data, property_launch_date_time, photographer_date_time, sign_in_aggrement } = context.state;
    const { PhotographerDateTimeDispatch, SignInAgreementDispatch } = context.actions;

    const [ article_data, setArticleData ] = useState('');
    const [ playVideo, setPlayVideo ] = useState(false);
    const { loading, error, data } = GET_ARTICLE('photography-date-and-time');

    const { data:photographer_data } = GET_PHOTOGRAPHER_NAME_PROPERTY(photographer_date_time.Photographer);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    useEffect(()=>{
        SignInAgreementDispatch(props.PreviewData.sign_in_agreement)
    }, [props.PreviewData]); 

    useEffect(()=>{
        //console.log("property_data", props.PreviewData);

        var property_data = props.PreviewData;

        if(photographer_data && photographer_data.photographers.length > 0)
        {
            //console.log("appointment", property_data);

            var appointment_id = property_data.appointment && property_data.appointment.Id;
            var urls = property_data.urls;
            var property_id = property_data.id;
            
            var custom_presentation = {
                "PropertyLaunchDateTime": property_launch_date_time.LaunchStart,
                "PropertyLaunchUser": property_launch_date_time.CSM_Name,
                "PhotographyDateAndTime360": photographer_date_time.PhotographyStart,
                "PhotographyUser360": photographer_data.photographers[0].Name
            };

            var post_data = {...sign_in_aggrement, Id:appointment_id, CustomPresentation:custom_presentation, URLs:urls};

            var res = SignInAgreement_API(property_id, post_data).then(res => {

                console.log("res_launch", res);
                if(res.status === 200){
                    PhotographerDateTimeDispatch({...photographer_date_time, Photographer_Name:photographer_data.photographers[0].Name})
                } else{
    
                }
            });
        }
    }, [photographer_data]);

    useEffect(() => {
        if(photographer_date_time && photographer_date_time.PhotographyStart){
            var property_launch_date = photographer_date_time && photographer_date_time.PhotographyStart && dateFormat(photographer_date_time.PhotographyStart, "yyyy-mm-dd");
            var property_launch_time = photographer_date_time && photographer_date_time.PhotographyStart && dateFormat(photographer_date_time.PhotographyStart, "h:MMtt");
            
            setTimeout( function(){
                var check_selected_date = $("#photographer_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']');

                if(check_selected_date.length === 1){
                    $("#photographer_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls').addClass('light_red');
                    $("#photographer_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']').removeClass('light_red');
                    $( ".fc-event-time:contains('"+property_launch_time+"')", '#photographer_cal .fc-scrollgrid-sync-table .fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']').parent().parent('.fc-daygrid-event-harness').addClass('actv');
                }                
            },1000);
        }
    }, [photographer_date_time]);

    setTimeout( function(){
        $("#photographer_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
    }, 300);
  
      
    if(isBrowser()){
        setTimeout( function(){
            $("#photographer_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
        }, 500);

        $('body').on('click', '#photographer_cal button.fc-prev-button', function() {
            //do something
            setTimeout( function(){
                $("#photographer_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
            }, 300);
            });
            
        $('body').on('click', '#photographer_cal button.fc-next-button', function() {
        //do something
            //alert("test2");
            setTimeout( function(){
                $("#photographer_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
            }, 300);
        });
    }

    
    const handleEventClick = (events) => {
        //console.log("events", events.el);
        //console.log("events2", events.event._def);
        $('#photographer_cal .fc-daygrid-event-harness').removeClass('actv');
        $(events.el.parentElement).addClass('actv');
        $("#photographer_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls').addClass('light_red');
        $(events.el.parentElement.parentElement.parentElement.parentElement).removeClass('light_red');
        //$(events.el.parentElement.parentElement.parentElement.parentElement).append('<div class="tooltipText">'+events.event._def.extendedProps.description+'</div>');
        PhotographerDateTimeDispatch(events.event._def.extendedProps);
    }

    //console.log("photoDateTime",  props.photoDateTime);
    //console.log("photographer_date_time",  photographer_date_time, property_launch_date_time);
  
    return <>
       <div className="section-wrapper calendar_cnt photograph  mt-5">
          <Container>
          <Title_Block Title={props.Title ? props.Title : article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={''} PrevPage={''} />
          
            {article_data && article_data.Content && <p className="fig-content mb_cnt font_20">{HTMLReactParser(article_data.Content)}</p> }


                <div className={`calendar-wrapper ${props.Type === "preview" && props.present_type === "custom_client" ? "preview" : ''}`} id="photographer_cal">
                    {
                        ((props.propertyDateTime &&  props.propertyDateTime.length > 0) && (props.photoDateTime &&  props.photoDateTime.length > 0)) ? 
                        <FullCalendar
                            selectable={true}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            ref={calendarComponentRef}
                            weekends={true}                    
                            events={props.photoDateTime}
                            eventClick={props.Type === "preview" && props.present_type === "custom_client" ? false : handleEventClick}
                            displayEventTime={true}
                            showNonCurrentDates={false}
                            initialDate={props.photoDateTime[0].start}
                            eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                            height={"720px"}
                        /> :
                        <FullCalendar
                            selectable={true}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            ref={calendarComponentRef}
                            weekends={true}                    
                            displayEventTime={true}
                            showNonCurrentDates={false}
                            eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                            height={"720px"}
                        />
                    }
                    
                </div>
            {/* {
                props.Type === "preview" && props.present_type === "custom_client" ? (
                    <div className="calendar-wrapper preview" id="photographer_cal">
                        {
                            props.photoDateTime &&  props.photoDateTime.length > 0 && 
                            <FullCalendar
                                selectable={false}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={calendarComponentRef}
                                weekends={true}                    
                                events={props.photoDateTime}
                                displayEventTime={true}
                                showNonCurrentDates={false}
                                initialDate={props.photoDateTime[0].start}
                                eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                height={"720px"}
                            />
                        }
                        
                    </div>
                ) : (
                    <div className="calendar-wrapper" id="photographer_cal">
                        {
                            props.photoDateTime &&  props.photoDateTime.length > 0 && 
                            <FullCalendar
                                selectable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={calendarComponentRef}
                                weekends={true}                    
                                events={props.photoDateTime}
                                eventClick={handleEventClick}
                                displayEventTime={true}
                                showNonCurrentDates={false}
                                initialDate={props.photoDateTime[0].start}
                                eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                height={"720px"}
                            />
                        }
                        
                    </div>
                )
            } */}
            {/* calendar-wrapper */}
            </Container>
        </div>
    </>
}

export default Photographer_DateTime
