import React, { useState, useEffect } from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import dateFormat from 'dateformat';

import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import CalendarImg from "../../assests/images/calendar.png";
import { Link } from "gatsby"
import SEO from "../../components/seo"
//import { Calendar, momentLocalizer } from "react-big-calendar"
import {config} from '../../components/Common/constant';
import { GET_ARTICLE } from "../../queries/common_use_query";
import { useContainerContext } from "../../store/ContainerContext";

import Photographer_DateTime from  "../../components/modules/photographer_date_time";

function PhotographerDateTime(props){
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    const context = useContainerContext();
    const { property_data, property_launch_date_time, photographer_date_time } = context.state;
    const { PhotographerDateTimeDispatch } = context.actions;

    const [ article_data, setArticleData ] = useState('');
    const [ photoDateTime, setPhotoDateTime ] = useState([]);
    const calendarComponentRef = React.createRef();

    const { loading, error, data } = GET_ARTICLE('photography-date-and-time');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    useEffect(()=>{
        var calendarEvents = [];
        property_launch_date_time && property_launch_date_time.PhotographyAppointments && property_launch_date_time.PhotographyAppointments.length > 0 && property_launch_date_time.PhotographyAppointments.map((item,index) => {
            calendarEvents.push({title: item.Photographer, date: item.PhotographyStart, start: item.PhotographyStart, PhotographyStart: item.PhotographyStart, PhotographyEnd: item.PhotographyEnd, Photographer:item.Photographer})
        })

        setPhotoDateTime(calendarEvents);
    }, [property_launch_date_time]);

    return <>
    <SEO title="Photograper Date and Time" description="Photograper Date and Time"/>
    
    <div className="pre-wrapper">
      
      <Header />
    
        {/* <div className="section-wrapper ">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={config.custom_prop_datetime} NextLink={''} />

            {article_data && article_data.Content && <p className="fig-content font_20">{HTMLReactParser(article_data.Content)}</p> }
            <div className="calendar-wrapper">
                <FullCalendar
                    selectable={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    ref={calendarComponentRef}
                    weekends={true}                    
                    events={photoDateTime}
                    //dateClick={(e) => chooseDate(calendarEvents, e)}
                    eventClick={handleEventClick}
                    displayEventTime={true}
                    showNonCurrentDates={false}
                    eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                />
            </div>

            </Container>
        </div> */}

      <Photographer_DateTime photoDateTime={photoDateTime} />
      
      <NavLink tab_name="Schedule" prevLink={config.custom_marketing} nextLink={property_data && parseInt(property_data.price_expectancy) > 200000 ? config.custom_plan : config.custom_teams} />

    </div>
  </>
}

export default PhotographerDateTime